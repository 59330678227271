//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { WebView } from '../../common/app'
import { getList, reservation } from '../../common/api'
import gold from '../../components/gold.vue'
import yuYue from '../../components/yuyue.vue'
export default {
  components: {
    gold,
    yuYue,
  },
  data() {
    return {
      list: [], // 猪崽列表
      popshow: false, //预约弹窗
      isReserve: false, // 是否预约
      is_reservation: '', // 是否预约 0 无  1 有
      yuMoney: 0, // 预约金
      goldShow: false, //金币不足弹窗
      havePig: '', // 是否有猪崽 0无 1有
      isShow: false,
    }
  },
  computed: {},
  watch: {},

  created() {
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      console.log('Safari')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      console.log('进入ios')
      if (this.$store.state.access_id) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    } else {
      console.log('进入安卓')
      this.isShow = true
    }
  },
  mounted() {
    console.log('id', this.$store.state.access_id)
    WebView.getAccessId = this.getAccessId

    this.getAccessId()
  },
  methods: {
    getAccessId(accessId = this.$store.state.access_id) {
      console.log(accessId)
      this.$store.commit('SET_ACCESS_ID', accessId)
      this.getLists()
    },
    //获取列表
    async getLists() {
      let res = await getList({ access_id: this.$store.state.access_id })
      if (res.data.code == '40004') {
        this.list = res.data.data
      }
      if (res.data.code == '90001') {
        this.isReserve = true
      }
      if (res.data.code == 200) {
        this.list = res.data.data.list
        this.havePig = res.data.data.no_pig
        this.yuMoney = res.data.data.deposit_money
        this.is_reservation = res.data.data.is_reservation
      }
    },
    // 去预约
    gorReserve() {
      this.popshow = true
    },
    // 去详情 type 0 正常详情   1 预约详情
    goDetail(item, type) {
      this.$store.commit('SET_P_ID', item)
      this.$router.push({
        path: '/detail',
        query: {
          type: type,
        },
      })
    },
    // 去购买
    goBuy(pid, price, total, money) {
      this.$store.commit('SET_P_ID', pid)
      this.$router.push({
        path: '/protocol',
        query: {
          price: price,
          total: total,
          money: money,
        },
      })
    },
    pageClose() {
      window.location.href = 'uniwebview://closepage?test=closepage'
    },
    // 关闭预约弹窗
    closePop(val) {
      console.log(val, 'val')
      this.popshow = val
    },
    goldUpdate: (function () {
      let timer = null
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          this.goldShow = !this.goldShow
        }, 300)
      }
    })(),
    adoption: (function () {
      let timer = null
      return function () {
        clearTimeout(timer)
        timer = setTimeout(() => {
          reservation({ access_id: this.$store.state.access_id }).then(
            (res) => {
              if (res.data.code == '80001') {
                //金币不足
                this.popshow = !this.popshow
                this.goldShow = true
              }
              if (res.data.code == 200) {
                this.popshow = !this.popshow
                this.getLists()
              }
            },
          )
        }, 300)
      }
    })(),
  },
}
