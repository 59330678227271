//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    popshow: {
      type: Boolean,
      default: false,
    },
    yuMoney: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  methods: {
    adopt() {
      this.$emit("adoption");
    },
    close: (function () {
      let timer = null;
      return function () {
        clearTimeout(timer);
        timer = setTimeout(() => {
          this.$emit("closePop", false);
        }, 300);
      };
    })(),
  },
};
