//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    goldShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit("goldUpdate", !this.goldShow);
    },
    gobuy() {
      console.log("充值");
      window.location.href = "uniwebview://recharge?test=recharge";
    },
  },
};
